var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eventManagement-form-box" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "风险信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.ruleForm,
                    "label-width": "150px",
                    disabled: _vm.formType == "view",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "风险源", prop: "sourceId" } },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "sourceName",
                                    value: "id",
                                  },
                                  placeholder: "请选择事故类别",
                                  type: "tree",
                                  dic: _vm.strisksourceList,
                                },
                                model: {
                                  value: _vm.ruleForm.sourceId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sourceId", $$v)
                                  },
                                  expression: "ruleForm.sourceId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "事故类型", prop: "event" } },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "dictValue",
                                    value: "dictKey",
                                  },
                                  placeholder: "请选择事故类别",
                                  type: "tree",
                                  dic: _vm.risk_envent_type,
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.ruleForm.event,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "event", $$v)
                                  },
                                  expression: "ruleForm.event",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管控层级",
                                prop: "controlLevel",
                              },
                            },
                            [
                              _c("avue-select", {
                                attrs: {
                                  props: {
                                    label: "dictValue",
                                    value: "dictKey",
                                  },
                                  placeholder: "请选择事故类别",
                                  type: "tree",
                                  dic: _vm.RISK_CONTROL_LEVEL,
                                },
                                model: {
                                  value: _vm.ruleForm.controlLevel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "controlLevel", $$v)
                                  },
                                  expression: "ruleForm.controlLevel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "风险源描述", prop: "riskName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder:
                                    "请输入事故发生的简要经过和发生的原因",
                                },
                                model: {
                                  value: _vm.ruleForm.riskName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "riskName", $$v)
                                  },
                                  expression: "ruleForm.riskName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发生可能性:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择发生可能性" },
                                  on: { change: _vm.handleChangeL },
                                  model: {
                                    value: _vm.ruleForm.lecdL,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "lecdL", $$v)
                                    },
                                    expression: "ruleForm.lecdL",
                                  },
                                },
                                _vm._l(_vm.L, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.definition,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "170px",
                                label: "人员暴露风险环境繁度:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择人员暴露风险环境繁度",
                                  },
                                  on: { change: _vm.handleChangeE },
                                  model: {
                                    value: _vm.ruleForm.lecdE,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "lecdE", $$v)
                                    },
                                    expression: "ruleForm.lecdE",
                                  },
                                },
                                _vm._l(_vm.E, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.definition,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "可能后果:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择可能后果" },
                                  on: { change: _vm.handleChangeC },
                                  model: {
                                    value: _vm.ruleForm.lecdC,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "lecdC", $$v)
                                    },
                                    expression: "ruleForm.lecdC",
                                  },
                                },
                                _vm._l(_vm.C, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.definition,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "L:" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                on: { input: _vm.handleChange },
                                model: {
                                  value: _vm.ruleForm.lecdLScore,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "lecdLScore", $$v)
                                  },
                                  expression: "ruleForm.lecdLScore",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "E:" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                on: { input: _vm.handleChange },
                                model: {
                                  value: _vm.ruleForm.lecdEScore,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "lecdEScore", $$v)
                                  },
                                  expression: "ruleForm.lecdEScore",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "C:" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                on: { input: _vm.handleChange },
                                model: {
                                  value: _vm.ruleForm.lecdCScore,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "lecdCScore", $$v)
                                  },
                                  expression: "ruleForm.lecdCScore",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "class-D", attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "D:" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: { min: 0, default: "0", disabled: true },
                                model: {
                                  value: _vm.ruleForm.lecdDScore,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "lecdDScore", $$v)
                                  },
                                  expression: "ruleForm.lecdDScore",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "LEC风险评价法：L（Likelihood，事故发生的可能性）、E（Exposure，人员暴露于危险环境中的频繁程度）、C（Consequence，一旦发生事故可能造成的后果）。给三种因素的不同等级分别确定不同的分值，再以三个分值的乘积D（danger，危险性）来评价风险的大小。注：自行选择风险等级后D值会清空，采用用户的选择",
                                    placement: "right-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: {
                                      "margin-left": "10px",
                                      "font-size": "20px",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "风险等级:", prop: "riskLevel" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "系统自动计算（也可自行选择）",
                                    clearable: "",
                                  },
                                  on: { change: _vm.riskLevelChange },
                                  model: {
                                    value: _vm.ruleForm.riskLevel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "riskLevel", $$v)
                                    },
                                    expression: "ruleForm.riskLevel",
                                  },
                                },
                                _vm._l(_vm.D, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.levelName,
                                      value: item.levelName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("head-layout", { attrs: { "head-title": "管控措施" } }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm1",
                  attrs: {
                    model: _vm.ruleForm,
                    "label-width": "150px",
                    disabled: _vm.formType == "view",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "工程技术",
                                prop: "treatEngineer",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请输入工程技术内容",
                                },
                                model: {
                                  value: _vm.ruleForm.treatEngineer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "treatEngineer", $$v)
                                  },
                                  expression: "ruleForm.treatEngineer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管理措施",
                                prop: "treatManagment",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请输入管理措施内容",
                                },
                                model: {
                                  value: _vm.ruleForm.treatManagment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "treatManagment",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.treatManagment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "培训教育",
                                prop: "treatTraining",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请输入培训教育内容",
                                },
                                model: {
                                  value: _vm.ruleForm.treatTraining,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "treatTraining", $$v)
                                  },
                                  expression: "ruleForm.treatTraining",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "个体防护",
                                prop: "treatProtection",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请输入个体防护内容",
                                },
                                model: {
                                  value: _vm.ruleForm.treatProtection,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "treatProtection",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.treatProtection",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "应急处置",
                                prop: "treatEmergency",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请输入个体防护内容",
                                },
                                model: {
                                  value: _vm.ruleForm.treatEmergency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "treatEmergency",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.treatEmergency",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }